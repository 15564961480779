<template>
  <div v-loading="loading">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
            <i class="flaticon2-delivery-package text-primary"></i>
          </span>
          <h3 class="card-label">
            <b class="title-header">フォーム詳細・編集</b>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <el-form
          ref="ruleForm"
          label-position="left"
          label-width="40%"
          class="demo-ruleForm form-base"
          v-if="formBase.formBase"
        >
          <div class="row mb-8">
            <div class="col-12 col-md-10 col-xl-5">
                <label class="el-form-item__label " for="">フォーム名
                    <span class="text-danger">*</span>
                </label>
              <el-form-item
                label=""
                class="custom_reponsive"
                prop="form_name"
              >
                <el-input
                  @keypress.enter.native.prevent="confirmEdit"
                  maxlength="40"
                  v-model="formBase.formBase.form_name"
                  class="bg-gray-item"
                  placeholder="フォーム名を入力"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row mb-8">
            <div class="col-md-10 col-xl-5">
                <label class="el-form-item__label " for="">フォームURL
                    <span class="text-danger">*</span>
                </label>
              <el-form-item
                class="custom_reponsive"
                label=" "
                prop="form_url"
              >
                <el-input
                  @keypress.enter.native.prevent="confirmEdit"
                  maxlength="300"
                  v-model="formBase.formBase.form_url"
                  class="bg-gray-item"
                  placeholder="フォームURLを入力"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row mb-8">
            <div class="col-md-10 col-xl-5">
                <label class="el-form-item__label " for="">項目グループ名
                    <span class="text-danger">*</span>
                </label>
              <el-form-item
                label=""
                prop="item_group_names"
                class="custom_reponsive"
              >
                <el-input
                  maxlength="1000"
                  class="bg-gray-item"
                  v-model="item_group_names"
                  type="textarea"
                  placeholder="項目グループ名を入力"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row mb-8">
            <div class="col-md-10 col-xl-5">
                <label class="el-form-item__label " for="">項目グループ名（中）</label>
              <el-form-item label="" prop="item_group_name_middle" class="custom_reponsive">
                <el-input maxlength="3000" class="bg-gray-item" v-model="item_group_name_middle" type="textarea" placeholder="項目グループ名（中）を入力"></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="row mb-8">
            <div class="col-md-10 col-xl-5">
                <label class="el-form-item__label " for="">ステータス
                    <span class="text-danger">*</span>
                </label>
              <el-form-item
                label=""
                prop="form_status"
                class="custom_reponsive"
              >
                <el-select
                placeholder="ステータス"
                v-model="formBase.formBase.form_status"
                type="text"
                style="width:100%;height: 10%"
              >
                <el-option v-for="status in formStatus" :value="status.value" :label="status.label" :key="status.value">{{ status.label }}</el-option>
              </el-select>
              </el-form-item>
            </div>
          </div>
          <!-- part 2 -->
<!--          phase 2-->
          <div class="row mb-8">
            <div class="col-md-10 col-xl-5">
              <el-form-item label="親フォーム名" prop="parent_form_id" class="custom_reponsive">
                <el-select placeholder="選択してください" v-model="formBase.formBase.parent_form_id" style="width:100%;height: 10%">
                  <el-option label="なし" :value="null"></el-option>
                <el-option v-for="form in formBase.listForm"
                           :value="form.form_id" :label="form.form_name" :key="form.form_id">
                    {{ form.form_name }}
                </el-option>
              </el-select>
              </el-form-item>
            </div>
          </div>
            <div v-if="currentUser.super_user" class="row mb-8">
                <div class="col-12 col-md-10 col-xl-5">
                    <label class="el-form-item__label" for=""> テナント</label>
                    <el-form-item class="item-title-form item-fome-base" label=""
                                  prop="tenant_code">
                        <el-select @change="changeTenant(true)" class="w-100"  v-model="formBase.formBase.tenant_code" placeholder="選択してください">
                            <el-option label="なし" value=" "></el-option>
                            <el-option
                                v-for="(item, index) in listTenant"
                                :key="index"
                                :label="item.tenant_name"
                                :value="item.tenant_code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
          <div class="row mb-8">
            <div class="col-md-10 col-xl-5">
              <el-form-item label="適用承認プロセス名" prop="approval_name" class="custom_reponsive">
                <el-select @change="changeApproval()" placeholder="選択してください" v-model="approval_id" type="text" style="width:100%;height: 10%">
                    <el-option label="なし" value=" "></el-option>
                    <el-option v-for="approval in list_approval"
                               :value="approval.approval_id" :label="approval.approval_name" :key="approval.approval_id">
                        {{ approval.approval_name }}
                    </el-option>
              </el-select>
              </el-form-item>
            </div>
          </div>

          <div class="row mb-8">
              <div class="col-md-10 col-xl-5">
                  <label class="el-form-item__label"></label>
                  <el-form-item class="item-title-form item-fome-base " label="">
                      <el-checkbox v-model="formBase.formBase.display_new_account">新規入力</el-checkbox>
                  </el-form-item>
              </div>
          </div>

            <div class="row mb-8">
                <div class="col-md-10 col-xl-5">
                    <label class="el-form-item__label"></label>
                    <div class="henshu-form-base">
                        <el-form-item class="item-title-form item-fome-base henshu-form-child" label="" prop="copy_flag">
                            <el-checkbox :disabled="!formBase.formBase.parent_form_id"
                                         v-model="formBase.formBase.copy_flag">回答時に回答項目を親フォームからコピー
                            </el-checkbox>
                        </el-form-item>
                        <el-form-item class="item-title-form item-fome-base " label="" prop="henshu_flg">
                            <el-checkbox v-model="formBase.formBase.henshu_flg">申請者が入力完了後にも情報修正を可能</el-checkbox>
                        </el-form-item>
                    </div>
                </div>
            </div>

            <div class="row mb-8">
                <div class="col-md-10 col-xl-5">
                    <label class="el-form-item__label"></label>
                    <el-form-item class="item-title-form item-fome-base" label="">
                        <el-checkbox v-model="formBase.formBase.copy_group_name" :disabled="formBase.formBase.special_item">回答時に項目グループ単位での解答欄複製を許可する</el-checkbox>
                    </el-form-item>
                </div>
            </div>
            <div class="row mb-8">
                <div class="col-md-10 col-xl-5">
                    <label class="el-form-item__label"></label>
                    <el-form-item class="item-title-form item-fome-base" label="">
                        <el-checkbox v-model="formBase.formBase.copy_group_name_middle" :disabled="formBase.formBase.special_item">回答時に項目グループ（中）単位での解答欄複製を許可する</el-checkbox>
                    </el-form-item>
                </div>
            </div>

            <div class="row mb-8">
                <div class="col-md-10 col-xl-5">
                    <label class="el-form-item__label"></label>
                    <el-form-item class="item-title-form item-fome-base " label="">
                        <el-checkbox :disabled="!formBase.formBase.parent_form_id || formBase.formBase.form_status != 1" v-model="formBase.formBase.mail_flag">親フォームの回答者に「子フォーム回答依頼メール」を発出</el-checkbox>
                    </el-form-item>
                </div>
            </div>
            <div class="row mb-8">
                <div class="col-md-10 col-xl-5">
                    <label class="el-form-item__label"></label>
                    <el-form-item class="item-title-form item-fome-base " label="" prop="kaiso_flg">
                        <el-checkbox  :disabled="disabled_check" v-model="formBase.formBase.kaiso_flg"> 階層フォームとする</el-checkbox>
                    </el-form-item>
                </div>
            </div>
            <div class="row mb-8">
                <div class="col-md-10 col-xl-5">
                    <label class="el-form-item__label"></label>
                    <el-form-item class="item-title-form item-fome-base " label="" prop="tenant_copy_flg">
                        <el-checkbox :disabled="disabled_check" v-model="formBase.formBase.tenant_copy_flg">子テナントにコピーする</el-checkbox>
                    </el-form-item>
                </div>
            </div>
          <div class="row mb-8">
              <div class="col-md-10 col-xl-5">
                  <label class="el-form-item__label"></label>
                  <el-form-item class="item-title-form item-fome-base " label="" prop="special_item">
                      <el-checkbox :disabled="formBase.formBase.display_special_item || formBase.formBase.copy_group_name || formBase.formBase.copy_group_name_middle || formBase.formBase.check_copy_item" v-model="formBase.formBase.special_item">条件分岐・自動計算の機能</el-checkbox>
                  </el-form-item>
              </div>
          </div>

<!--          phase 2-->
          <!-- end part 2 -->
          <el-form-item prop="action" class="button-search" label-width="0px">
              <el-row>
                  <el-button
                      type="primary"
                      @click.prevent="confirmEdit"
                  >入力完了</el-button>
                  <button
                      type="button"
                      @click="confirmDelete()" class="btn btn-danger ml-5">
                      削除
                  </button>
              </el-row>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Popup id="confirm" title="確認" :content="title" :title_button="no_confirm">
        <b-button @click.prevent="editForm()" class="btn-primary">{{ $t("YES") }}</b-button>
    </Popup>
    <Popup id="error-edit" :content="title" :title_button="close_popup">
    </Popup>
      <Popup id="confirm-delete" :content="content_delete_form" title_button="いいえ">
          <b-button @click.prevent="deleteFormBase" class="btn-primary">{{ $t("YES") }}</b-button>
      </Popup>
      <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                 :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
          <template slot="header">
              <div class="textwidget text-center">
                  {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
              </div>
          </template>
      </my-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { GET_FORM_BASE,HANDLE_EDIT_FORM,DELETE_FORM_BASE_BY_ID} from "@/core/services/store/formBase.module";
import * as constants from "@/core/config/constant";
import Popup from "@/view/components/Popup";
import {GET_ALL_TENANT} from "@/core/services/store/mtenant.module";

export default {
  name: "edit",
  components: {
    Popup
  },
  data() {
    return {
      loading: false,
      item_group_names: '',
      item_group_name_middle: '',
      constants: constants,
      formStatus: constants.FORM_BASE_STATUS,
      title: '',
      no_confirm: this.$t("NO"),
      close_popup: this.$t("close_popup_error"),
      approval_id: '',
      content_delete_form: '',
      not_permisson_popup:false,
      disabled_check: false,
      listTenant: [],
      list_approval: [],
    };
  },
  computed: {
    ...mapGetters(["formBase","currentUser"]),
    queryParam() {
      return this.$route.params;
    },
  },
  async created() {
    this.loading = true;
      if (this.hasPermissonCheck('form_flg')){
          await this.getFormBase(this.queryParam.form_id);
          this.approval_id = this.formBase.formBase.approval? this.formBase.formBase.approval.approval_id : '';
          // this.list_approval = this.formBase.listApproval;
          this.convertItemGroupName();
          if (this.currentUser.super_user){
              this.listTenant = await this.getAllTenant();
          }
          this.changeTenant();
      }else {
          this.not_permisson_popup = true
      }
      this.changeApproval();
    this.$nextTick(() => {
      if(this.formBase.formBase.parent_form_id == null || this.formBase.formBase.parent_form_id == '') {
        this.formBase.formBase.copy_flag = false
        this.formBase.formBase.mail_flag = false
      }
    });
    this.loading = false;

  },
  methods: {
    ...mapActions([GET_FORM_BASE,HANDLE_EDIT_FORM,DELETE_FORM_BASE_BY_ID,GET_ALL_TENANT]),
    convertItemGroupName(){
        var obj =JSON.parse(this.formBase.formBase.item_group_names);
          var arr = []
              Object.keys(obj).forEach(function(key){
                arr.push(obj[key].trim())
            })
            this.item_group_names = arr.join(', ');
        if (this.formBase.formBase.item_group_name_middle){
            var objMiddle =JSON.parse(this.formBase.formBase.item_group_name_middle);
            var arrMiddle = []
            Object.keys(objMiddle).forEach(function(key){
                arrMiddle.push(objMiddle[key].trim())
            })
            this.item_group_name_middle = arrMiddle.join(', ');
        }
    },
    editForm(){
      this.turnOffErrorMessage("ruleForm");
      this.handleEditForm(
        {
          form_id: this.formBase.formBase.form_id,
          form_name: this.formBase.formBase.form_name,
          form_url: this.formBase.formBase.form_url,
          item_group_names: this.item_group_names,
          item_group_name_middle: this.item_group_name_middle,
          form_status: this.formBase.formBase.form_status,
          parent_form_id: this.formBase.formBase.parent_form_id,
          approval_id: this.approval_id,
          copy_flag: this.formBase.formBase.copy_flag,
          henshu_flg: this.formBase.formBase.henshu_flg,
          mail_flag: this.formBase.formBase.mail_flag,
          copy_group_name: this.formBase.formBase.copy_group_name,
          copy_group_name_middle: this.formBase.formBase.copy_group_name_middle,
          kaiso_flg: this.formBase.formBase.kaiso_flg,
          tenant_copy_flg: this.formBase.formBase.tenant_copy_flg,
          tenant_code: this.formBase.formBase.tenant_code,
          display_new_account: this.formBase.formBase.display_new_account,
          special_item: this.formBase.formBase.special_item,
        }
      ).then((response)=>{
        if(response.data.code==constants.STATUS_CODE.SUCCESS){
           this.notifySuccess(this.$t("UPDATE_SUCCESS"));
            this.$bvModal.hide("confirm");
            let self = this;
            setTimeout(function () {
              self.$router.push({
                name: "form-base-list",
              });
            }, 500);
        }else {
        this.showErrorMessage(response.data.data, "ruleForm");
        this.$bvModal.hide("confirm");
      }
      }).catch(e=>{
        this.title = e.response.data.message;
        this.$bvModal.show("error-edit");
        this.$bvModal.hide("confirm");
      })
    },
    confirmEdit(){
      this.title = this.$t("CONFIRM_EDIT" , {form_name: this.formBase.formBase.form_name})
      this.$bvModal.show("confirm");
    },
      confirmDelete(){
          this.content_delete_form = this.$t("CONFIRM_DELETE" , {form_name: this.formBase.formBase.form_name});
          this.$bvModal.show("confirm-delete");
      },
      async deleteFormBase(){
          this.loading = true;
          let data = await this.deleteFormBaseById(this.formBase.formBase.form_id);
          if (data){
              this.notifySuccess(this.$t("DELETE_SUCCESS"));
              this.$bvModal.hide("confirm-delete");
              this.$router.push({name: "form-base-list"});
          }
          this.loading = false;
      },
      changeApproval(){
          let findCurrentApproval = this.formBase.listApproval.find(data => data.approval_id === this.approval_id);
          if (findCurrentApproval && findCurrentApproval.approval_kaiso > 0){

          }else{
          }
      },
      changeTenant(isCreate = false){
        if (isCreate) {
            this.approval_id = ''
        }
          // this.approval_id = ''
          this.list_approval = this.formBase.listApproval.filter(data => {
              if (data.tenant_code === this.formBase.formBase.tenant_code){
                  return true;
              }
              else if((this.formBase.formBase.tenant_code === ' ' || !this.formBase.formBase.tenant_code) && !data.tenant_code){
                  return true;
              }
              return false;
          });
          let currentApproval = this.list_approval.find(data => data.approval_id === this.formBase.formBase.approval_id);
          if (!currentApproval){
              this.formBase.formBase.approval_id = '';
          }
      },
  },
};
</script>
